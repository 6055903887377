<template>
    <DebtWarning v-if="profile.isFinished" :isDebt="profile.value.isDebt" />

    <h2 class="text-align-center">Мої замовлення</h2>
    <p v-if="orders.isRunning">Loading...</p>
    <p v-else-if="orders.isError">{{ orders.error }}</p>
    <table v-else>
        <thead>
            <tr>
                <th>№</th>
                <th>Дата</th>
                <th>Кава</th>
                <th>Статус</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="order in orders.value">
                <td>№&nbsp;{{ order.pk }}</td>
                <td>{{ dateFmt(order.created) }}</td>
                <td>{{ orderItems(order).join(", ") }}</td>
                <td>{{ order.status }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
 import { reactive, ref } from 'vue';
 import { orderItems } from '@/common/selection.js';
 import { getMyProfileTask, getMyOrdersTask } from '@/common/api.js';
 import formatAddress from '@/common/address.js';

 export default {
     name: 'Історія замовлень',
     setup() {
         function dateFmt(isodate) {
             const date = new Date(isodate);
             return date.toLocaleDateString();
         };

         getMyProfileTask.perform();
         getMyOrdersTask.perform();

         return {
             profile: getMyProfileTask.last,
             orders: getMyOrdersTask.last,
             dateFmt,
             orderItems,
         }
     }
 }
</script>
